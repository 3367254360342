<div class="nav-top animated fadeInDown">
  <div class="logo">
    <span class="material-icons m-48">
      child_care
    </span>
  </div>
  <div class="registro">
    <div><strong>Qué alegría verte</strong>, ¿quieres
      <!-- <a [routerLink]="['login']" routerLinkActive="active"
        class=" a-color" href="#" title="iniciar sesión" alt="iniciar sesión"> -->
      <a class="a-color" data-toggle="modal" href="#ModalLogin" title="iniciar sesión" alt="iniciar sesión">iniciar
        sesión </a> o <a class="a-color" title="registrate" data-toggle="modal" href="#ModalRegistro" alt="registrate">
        regístrate </a>?
    </div>
    <div class="avatar">
      <span class="material-icons">
        person_outline
      </span>
      <!-- <i class="material-icons md-24">account_circle</i> -->
    </div>
  </div>
</div>