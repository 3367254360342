<!-- Menú principal de navegación entre las secciónes de la web/api-->

<nav class="navegacion nav-usuario animated fadeIn">
    <div class="contenido">
        <div>
            <div class="avatar-user">
                <img src="{{ arrUsuarioId[0]?.foto | urlimg }}" title="{{ arrUsuarioId[0]?.alias}}"
                    alt="{{ arrUsuarioId[0]?.alias}}">
            </div>

            <div class="menu">
                <ul>
                    <li class="boton">
                        <a [routerLink]="['usuario', 'perfil']" routerLinkActive="active" alt="Ir al Panel de Usuario"
                            title="Ir al Panel de Usuario">
                            <span class="icono"><span class="material-icons">
                                    emoji_people
                                </span></span>
                            <span class="texto">Panel de Usuario</span>
                        </a>
                    </li>
                    <li class="boton">
                        <a [routerLink]="['usuario', 'conversacion']" routerLinkActive="active"
                            alt="Ir a la sección de mensajes" title="Ir a la sección de mensajes">
                            <span class="icono icono-nesajes">
                                <span class="aviso">
                                    <i class="material-icons animated infinite heartBeat">adjust</i>
                                </span>
                                <i class=" material-icons md-24">mail_outline</i>
                                <span style="display:none" class="icono icono-nesajes ">
                                    <i class="material-icons md-24">drafts</i></span></span>
                            <span class="texto">Mensajes esperando</span>
                            <span style="display:none" class="aviso">Mensajes</span>
                        </a>
                    </li>
                    <li class="boton">
                        <a [routerLink]="['usuario', 'objeto']" routerLinkActive="active"
                            alt="ir a la sección de objeto" title="ir a la sección de objeto">
                            <span class="icono"><i class="material-icons md-24">outlined_flag</i></span>
                            <span class="texto">Objetos perdidos</span>
                        </a>
                    </li>
                    <li class="boton">
                        <a [routerLink]="" (click)="LoginOutAlarma()" alt="" title="">
                            <span class="icono"><i class="material-icons md-24">power_settings_new</i></span>
                            <span style="display:none" class="icono">
                                <i class="material-icons md-24">sentiment_dissatisfied</i>
                            </span>
                            <span class="texto">Salir de la sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>