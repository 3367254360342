<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Iniciar Sesión</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <!--  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"> -->
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- 👇 login usuario --  INICIO ------------------------------ -->
            <div class="login-inicio">
                <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nombre</label>
                        <input name="nombre" type="text" class="form-control form-control-lg" type="text"
                            placeholder="nombre" formControlName="nombre" required>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input name="email" type="text" class="form-control form-control-lg" type="text"
                            placeholder="email" formControlName="email" required>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Contraseña</label>
                        <input name="password" type="password" class="form-control form-control-lg" type="text"
                            placeholder="contraseña" formControlName="password" required>
                    </div>
                    <!--  <div class="alert alert-danger" role="alert">
                                                    A simple danger alert—check it out!<a href="#" class="alert-link">an example link</a>.
                                                </div> -->
                    <div>¿te has olvidado de la contraseña?</div>
                    <button type="submit" class="btn btn-primary">Enviar</button>
                    <p>{{errorMesage}}</p>
                </form>
            </div>
            <!-- 👆 login usuario --  FIN ------------------------------ -->
        </div>
        <!--  <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
    </div>
</div>