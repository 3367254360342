<!-- 👇 Mapa  -- INICIO ------------------------------ -->

<!-- 👇 Buscador  -- INICIO ------------------------------ -->
<div class="contenido-mapa">
    <h1>Hay que incluir los objetos "reales" perdidos</h1>
    <div class="buscador col-12">
        <div class="form-group">
            <form>
                <input type="text" class="form-control" id="place" #search [formControl]="searchControl"
                    autocomplete="off" autocapitalize="off" spellcheck="off">
            </form>
        </div>
        <div class="dirección"><img src="{{imagen| urlimg}}">{{direccion}}</div>
    </div>
</div>
<!-- 👆 Buscador --  FIN ------------------------------ -->
<!-- 👇 Google Mapa  -- INICIO ------------------------------ -->
<agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [fullscreenControl]="true">
    <!-- 👇 Marcadores  -- INICIO ------------------------------ -->
    <!-- 👇 Marcador localizacion  -- INICIO ------------------------------ -->
    <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="imagen| urlimg" [zIndex]="100"
        (mouseOver)="onMouseOver(infoWindow, $event)" (mouseOut)="onMouseOut(infoWindow, $event)">
        <agm-info-window #infoWindow>
            Tu, turu ru<br>:P
        </agm-info-window>
    </agm-marker>
    <!-- 👆 Marcador localizacion  --  FIN ------------------------------ -->
    <!-- 👇 Marcadores ficticios -- INICIO ------------------------------ -->
    <agm-marker [animation]="'DROP'" *ngFor="let f of ficticio" [latitude]="f.latitud" [longitude]="f.longitud"
        [iconUrl]="f.icono | urlimg" (mouseOver)="onMouseOver(infoWindow, $event)"
        (mouseOut)="onMouseOut(infoWindow, $event)">
        <agm-info-window #infoWindow>
            <div class="card-ficiticio">
                <div class="card">
                    <div class="imagen"><img class="card-img-top" src="{{f.foto| urlimg}}" alt="{{f.nombre}}"></div>
                    <div class="card-body">
                        <h5 class="card-title">{{f.nombre}}</h5>
                        <p class="card-text">{{f.descripcion}}</p>
                    </div>
                </div>
            </div>
        </agm-info-window>
    </agm-marker>
    <!-- 👆 Marcadores Ficticios  --  FIN ------------------------------ -->
    <!-- 👆 Marcadores  --  FIN ------------------------------ -->
</agm-map>
<!-- 👆 Google Mapa --  FIN ------------------------------ -->

<!-- 👆 Mapa --  FIN ------------------------------ -->