<div class="interface">
    <!-- ==================================================== -->
    <!-- 👇 Modal mensaje-- Contenido genereal rooter ------------------------- -->
    <!-- <div id="ModalConversacionMensaje" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ModalMensaje"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-mensaje" role="document">
            <div class="modal-content">
                <div class=" modal-header">
                    <h4 class="modal-title" id="exampleModalLongTitle">Enviar un mensaje a:</h4>
                    <div class="modal-avatar"><strong>@{{arrObjeto[objetoSeleccionado]?.usuario[0].alias}}</strong>
                        <div class="imagen"><img src="{{arrObjeto[objetoSeleccionado]?.usuario[0].foto |urlimg }}"
                                alt="@{{arrObjeto[objetoSeleccionado]?.usuario[0].alias}}"
                                title="@{{arrObjeto[objetoSeleccionado]?.usuario[0].alias}}"></div>
                    </div>
                    <button type="button" class="close " data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="formularioMensaje" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Escribe tu mensaje para
                                @{{arrObjeto[objetoSeleccionado]?.emisorNombre[0]?.alias}}</label>
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Asunto">
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Mensaje</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <input type="hidden" name="usuario_id" value="valor usuario">
                    </form>

                </div>

            </div>
        </div>
    </div> -->
    <!-- 👆 Modal mensaje--  FIN ------------------------------ -->
    <!-- 👇 Modal mapa-- Contenido genereal rooter ------------------------- -->
    <div id="ModalMapa" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="ModalMapa" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="alias">
                        <div class="avatar"><img src="{{arrObjeto[objetoSeleccionado]?.usuario[0].foto |urlimg }}"
                                alt="@{{arrObjeto[objetoSeleccionado]?.usuario[0].alias}}"
                                title="@{{arrObjeto[objetoSeleccionado]?.usuario[0].alias}}"></div>
                        <strong>@{{arrObjeto[objetoSeleccionado]?.usuario[0].alias}}</strong>
                    </div>
                    <div class="nombre">
                        <h2>{{arrObjeto[objetoSeleccionado]?.nombre}}</h2>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <!--                     <div
                        *ngIf="(arrObjeto[objetoSeleccionado]?.perdido==1) && (arrObjeto[objetoSeleccionado]?.encontrado==1)">
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">

                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home"
                                role="tab" aria-controls="v-pills-home" aria-selected="true">Perdido</a>

                            <a *ngIf="(arrObjeto[objetoSeleccionado]?.perdido==1)" class="nav-link active"
                                id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab"
                                aria-controls="v-pills-profile" aria-selected="false">Encontrado</a>
                            <a *ngIf="(arrObjeto[objetoSeleccionado]?.encontrado==0)" class="nav-link"
                                id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab"
                                aria-controls="v-pills-profile" aria-selected="false">Encontrado</a>

                        </div>
                    </div>

                    <div class="tab-content" id="v-pills-tabContent">
                        <div *ngIf="arrObjeto[objetoSeleccionado]?.perdido==1">
                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                aria-labelledby="v-pills-home-tab">
                                <agm-map [latitude]="arrObjeto[objetoSeleccionado]?.latitud_perdido"
                                    [longitude]="arrObjeto[objetoSeleccionado]?.longitud_perdido" [zoom]="zoom"
                                    [fullscreenControl]="true">
                                    <agm-overlay [latitude]="arrObjeto[objetoSeleccionado]?.latitud_perdido"
                                        [longitude]="arrObjeto[objetoSeleccionado]?.longitud_perdido">
                                        <div>
                                            <img style="cursor: pointer;" [ngClass]="'marca-mapa'"
                                                src="{{arrObjeto[objetoSeleccionado]?.foto| urlimg}}" />
                                        </div>
                                    </agm-overlay>
                                </agm-map>
                            </div>
                        </div>

                        <div *ngIf="(arrObjeto[objetoSeleccionado]?.perdido==0) && (arrObjeto[objetoSeleccionado]?.encontrado==1)"
                            class="tab-pane fade active" id="v-pills-profile" role="tabpanel"
                            aria-labelledby="v-pills-profile-tab">
                            <agm-map [latitude]="arrObjeto[objetoSeleccionado]?.latitud_encontrado"
                                [longitude]="arrObjeto[objetoSeleccionado]?.longitud_encontrado" [zoom]="zoom"
                                [fullscreenControl]="true">
                                <agm-overlay [latitude]="arrObjeto[objetoSeleccionado]?.latitud_encontrado"
                                    [longitude]="arrObjeto[objetoSeleccionado]?.longitud_encontrado">
                                    <div>
                                        <img style="cursor: pointer;" [ngClass]="'marca-mapa'"
                                            src="{{arrObjeto[objetoSeleccionado]?.foto| urlimg}}" />

                                    </div>
                                </agm-overlay>
                            </agm-map>
                        </div>

                        <div *ngIf="(arrObjeto[objetoSeleccionado]?.perdido==1) && (arrObjeto[objetoSeleccionado]?.encontrado==1)"
                            class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                            aria-labelledby="v-pills-profile-tab">

                            <agm-map [latitude]="arrObjeto[objetoSeleccionado]?.latitud_encontrado"
                                [longitude]="arrObjeto[objetoSeleccionado]?.longitud_encontrado" [zoom]="zoom"
                                [fullscreenControl]="true">
                                <agm-overlay [latitude]="arrObjeto[objetoSeleccionado]?.latitud_encontrado"
                                    [longitude]="arrObjeto[objetoSeleccionado]?.longitud_encontrado">
                                    <div>
                                        <img style="cursor: pointer;" [ngClass]="'marca-mapa'"
                                            src="{{arrObjeto[objetoSeleccionado]?.foto| urlimg}}" />

                                    </div>
                                </agm-overlay>
                            </agm-map>
                        </div>


                    </div> -->
                    <div *ngIf="arrObjeto[objetoSeleccionado]?.perdido==1" class="ribbon">
                        <span class="list-group-item fecha" class="ribbon1"><span>Perdid@</span></span>

                        <agm-map [latitude]="arrObjeto[objetoSeleccionado]?.latitud_perdido"
                            [longitude]="arrObjeto[objetoSeleccionado]?.longitud_perdido" [zoom]="zoom"
                            [fullscreenControl]="true">
                            <agm-overlay [latitude]="arrObjeto[objetoSeleccionado]?.latitud_perdido"
                                [longitude]="arrObjeto[objetoSeleccionado]?.longitud_perdido">
                                <div>
                                    <img style="cursor: pointer;" [ngClass]="'marca-mapa'"
                                        src="{{arrObjeto[objetoSeleccionado]?.foto| urlimg}}" />
                                </div>
                            </agm-overlay>
                        </agm-map>
                    </div>
                    <div *ngIf="arrObjeto[objetoSeleccionado]?.encontrado==1" class="ribbon">
                        <span class="list-group-item fecha" class="ribbon3"><span>Encontrad@</span></span>
                        <agm-map [latitude]="arrObjeto[objetoSeleccionado]?.latitud_encontrado"
                            [longitude]="arrObjeto[objetoSeleccionado]?.longitud_encontrado" [zoom]="zoom"
                            [fullscreenControl]="true">
                            <agm-overlay [latitude]="arrObjeto[objetoSeleccionado]?.latitud_encontrado"
                                [longitude]="arrObjeto[objetoSeleccionado]?.longitud_encontrado">
                                <div>
                                    <img style="cursor: pointer;" [ngClass]="'marca-mapa'"
                                        src="{{arrObjeto[objetoSeleccionado]?.foto| urlimg}}" />

                                </div>
                            </agm-overlay>
                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 👆 Modal mapa--  FIN ------------------------------ -->
    <!-- ==================================================== -->
    <!-- 👇 INICIO -- Contenido genereal rooter ------------------------- -->
    <div class="container-fluid">
        <section class="wrapper">
            <!-- 👇 Pantalla inicial --  INICIO ------------------------------ -->
            <div class="row">

                <div class="col-12 col-xl-3">
                    <h1>Objetos perdidos<br>y encontrados</h1>
                    <div class="buscador">
                        <div class="form-group">

                            <form>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Recipient's username"
                                        aria-label="Recipient's username" aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button"
                                            id="button-addon2">Buscar</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="row">
                        <div class="objetos-muestras">
                            <div class="objeto-muestra-imagen animated fadeInLeft" *ngFor="let o of arrObjeto">
                                <a class=""
                                    href="/objeto#{{o?.usuario[0].ID}}{{o?.usuario[0].nombre| slice:1:2}}{{o?.nombre| slice:1:2}}{{o?.usuario[0].apellido| slice:1:2}}">
                                    <img src="{{o.foto | urlimg }}" class="card-img-top"
                                        alt="objeto {{o.nombre}} de {{o?.usuario[0].nombre}}"
                                        title="objeto {{o.nombre}} de {{o?.usuario[0].nombre}}"></a>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-xl-9">
                    <!-- ==================================================== -->
                    <!-- 👇 Contenido frid --  INICIO ------------------------------ -->
                    <article>
                        <div class="div-flex">
                            <!-- 👇 Objetos inicial --  INICIO ------------------------------ -->
                            <div class="card-columns">
                                <div *ngFor="let o of arrObjeto; let i = index">

                                    <!-- <div id="{{o?.usuario[0].ID}}{{o?.usuario[0].nombre| slice:1:2}}{{o?.nombre| slice:1:2}}{{o?.usuario[0].apellido| slice:1:2}}"
    class="card animated fadeInDown delay-{{i}}s"> -->
                                    <div id="{{o?.usuario[0].ID}}{{o?.usuario[0].nombre| slice:1:2}}{{o?.nombre| slice:1:2}}{{o?.usuario[0].apellido| slice:1:2}}"
                                        class="card animated fadeInDown ribbon">

                                        <span *ngIf="(o?.perdido)==1" class="list-group-item fecha"
                                            class="ribbon1"><span>Perdid@</span></span>
                                        <span *ngIf="(o?.encontrado)==1" class="list-group-item fecha"
                                            class="ribbon3"><span>Encontrad@</span></span>

                                        <img src="{{o?.foto | urlimg }}" class="card-img-top"
                                            alt="objeto {{o?.nombre}} de {{o?.usuario[0].nombre}} {{o?.usuario[0].apellido}}"
                                            title="objeto
                                            {{o?.nombre}} de {{o?.usuario[0].nombre}} {{o?.usuario[0].apellido}}">
                                        <!-- {{o?.usuario[0].ID}}{{o?.usuario[0].nombre| slice:1:2}}{{o?.nombre| slice:1:2}}{{o?.usuario[0].apellido| slice:1:2}} -->
                                        <div class="card-body">
                                            <h5 class="card-title">{{o?.nombre}}</h5>
                                            <p class="card-text"><span style="transform: scaleX(-1);"
                                                    class="material-icons">
                                                    format_quote
                                                </span>{{o?.descripcion}}<span class="material-icons">
                                                    format_quote
                                                </span></p>
                                        </div>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item ">
                                                <div class="usuario"><strong>@{{o.usuario[0].alias}}</strong><img
                                                        src="{{o?.usuario[0].foto |urlimg }}"
                                                        alt="@{{o?.usuario[0].alias}}" title="@{{o?.usuario[0].alias}}">
                                                </div>
                                            </li>
                                            <li *ngIf="(o?.perdido)==1" class="list-group-item fecha">

                                                <span class="material-icons">
                                                    insert_invitation
                                                </span> Perdido el: {{o?.fecha_perdido | date : 'yyyy-MM-dd'}}</li>
                                            <li *ngIf="(o?.encontrado)==1" class=" list-group-item fecha"><span
                                                    class="material-icons">
                                                    insert_invitation
                                                </span> Encontrado el: {{o?.fecha_encontrado | date : 'yyyy-MM-dd'}}
                                            </li>
                                        </ul>
                                        <div class="card-body botones">
                                            <!--  <a href="#Modalmensaje" data-target="#ModalMensaje" data-toggle="modal"
                                                class="card-link" title="enviar mensaje a {{o?.usuario.alias}}"
                                                (onclick)="mandarID(o?.ID)" [data-usuario-id]="[o?.ID]"><span
                                                    class="material-icons">

                                                    insert_comment
                                                </span></a> -->
                                            <a href="#Modalmensaje" data-target="#ModalMensaje" data-toggle="modal"
                                                class="card-link" title="enviar mensaje a {{o?.usuario.alias}}"
                                                (click)="mandarID(i)"><span
                                                    class="material-icons">insert_comment</span></a>
                                            <a href="#ModalMapa" data-target="#ModalMapa" data-toggle="modal"
                                                class="card-link"
                                                title="ver el objeto {{o?.nombre}} de {{o?.usuario.alias}}"
                                                (click)="mandarID(i)">
                                                <span class=" material-icons">
                                                    location_on
                                                </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 👆 Objetos inicial --  FIN ------------------------------ -->
                        </div>
                    </article>
                    <!-- 👆 Contenido frid --  FIN ------------------------------ -->
                    <!-- ==================================================== -->
                </div>

            </div>
            <!-- 👆 Pantalla inicial --  FIN ------------------------------ -->
        </section>
    </div>
</div>
<script type="text/javascript">
    $('#ModalMensaje').modal(options);

    //$('#ModalMapa').modal(options);
    $('#ModalMapa').modal('show', function (e) {
        console.log("hola cara bola")
    })
</script>