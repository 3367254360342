<!-- 👇 Usuario avatar --  INICIO ------------------------------ -->

<div class="menu-usuario-lat">
    <div class="avatar">
        <a class="editar" alt="editar imagen" title="editar imagen"></a>
        <img src="{{ arrUsuarioId[0]?.foto | urlimg }}" title="usuario.alias" alt="usuario.alias">

    </div>
    <!-- {{ arrUsuarioId[0]|json}} -->
    <div class="avatar-info">
        <h2 class="alias"> <strong>@{{ arrUsuarioId[0]?.alias }}</strong></h2>
        <div class="nombre">{{ arrUsuarioId[0]?.nombre }} {{ arrUsuarioId[0]?.apellidos }}</div>
        <div><span class="material-icons">star</span><span class="material-icons">star</span><span
                class="material-icons">star</span><span class="material-icons">star_border</span></div>
        <div>eres un <strong>mega explorador</strong></div>
        <div><strong>nivel</strong> Indiana Jones</div>
    </div>
    <div class="boton-incluir"><button class="btn btn-primary btn-lg btn-block" [routerLink]="['objeto-crear']">Incuir
            nuevo objeto</button></div>
    <div class=" avatar-objetos">

        <div *ngIf="['arrUsuarioId[0]?.objetoPerdido.length>0']">
            <div class="avatar-objetos">
                <div class="titulo">
                    <h4 class="alias"> Cosicas Perdidas:</h4>
                </div>
                <div *ngFor="let obj of arrUsuarioId[0]?.objetoPerdido" class="imagen">
                    <a href="">
                        <img src="{{obj?.foto | urlimg}}" alt="" title="">
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="['arrUsuarioId[0]?.objetoEncontrado.length>0']" class=" avatar-objetos">
            <h4 class="alias"> Encontrado Has:</h4>
            <div *ngFor="let obj of arrUsuarioId[0]?.objetoEncontrado" class="imagen">
                <a href="">
                    <img src="{{obj?.foto |urlimg}}" alt="" title="">
                </a>
            </div>
        </div>
    </div>
    <!-- 👆 Usuario avatar--  FIN ------------------------------ -->
    <!-- {{ arrUsuarioId[0]|json }} -->