<!-- <google-map></google-map> -->
<div class="interface interface-flex">
  <!-- 👇 Pantalla inicial --  INICIO ------------------------------ -->
  <div class="container-fluid h-100 incio">
    <section class="wrapper h-100">
      <!-- 👇 Perfil usuario --  INICIO ------------------------------ -->
      <div class="row align-items-center h-100">
        <!-- 👇 Contenido frid --  INICIO ------------------------------ -->

        <div class="col-12 align-self-center">
          <div class="contenido-inico">
            <h1>LOST<br>THINGS</h1>
            <h2>Encuentra, busca, pierde</h2>
          </div>
        </div>
        <!-- 👆 Contenido frid --  FIN ------------------------------ -->
        <!-- ==================================================== -->
      </div>
      <!-- 👆 Pantalla inicial --  FIN ------------------------------ -->
    </section>
  </div>
</div>