<div class="nav-usuario-perfil animated fadeInDown">
    <div class="menu">
        <ul>
            <li class="boton">
                <a [routerLink]="['usuario']" routerLinkActive="active" alt="Ir al Panel de Usuario"
                    title="Ir al Panel de Usuario">
                    <span class="icono"><span class="material-icons">
                            emoji_people
                        </span></span>
                    <span class="texto">Panel de Usuario</span>
                </a>
            </li>

            <li class="boton">
                <a [routerLink]="['conversacion']" routerLinkActive="activa" alt="Ver conversaciones"
                    title="Ver conversaciones">
                    <span class="icono"><i class="material-icons md-24">mail_outline</i></span>
                    <span class="texto">Mensajes</span>
                </a>
            </li>
            <li class="boton">
                <a [routerLink]="['objeto']" routerLinkActive="active" alt="ir a la sección de objeto"
                    title="ir a la sección de objeto">
                    <span class="icono"><i class="material-icons md-24">outlined_flag</i></span>
                    <span class="texto">Objetos perdidos</span>
                </a>
            </li>
            <!-- <li class="boton">
                <a href="#" alt="" title="">
                    <span class="icono"><i class="material-icons md-24">pets</i></span>
                    <span class="texto">texto</span>
                </a>
            </li> -->
        </ul>
    </div>
</div>