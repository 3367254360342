<div class="interface interface-flex">

    <!-- 👇 INICIO -- Contenido genereal rooter ------------------------- -->
    <div class="container-fluid h-100">
        <section class="wrapper h-100">
            <!-- 👇 Perfil usuario --  INICIO ------------------------------ -->
            <div class="row align-items-center h-100">
                <!-- 👇 Contenido frid --  INICIO ------------------------------ -->
                <div class="col-6 align-self-center">
                    <div class="img-responsive">
                        <img [src]="'404.png' |urlimg"></div>
                </div>
                <div class="col-6 align-self-center">
                    <h1>¡ERROR 404!</h1>
                    <h2>¿un 404? <span>¡me cachis!</span></h2>
                    <h1>¿O es que te has perdido?</h1>
                    <p><strong>...nada...,</strong> tu tranquilo nosotros te buscamos :D.</p>
                    <p>Pero hantes asegurate, que:</p>
                    <ul>
                        <li><i class="fas fa-user-astronaut"></i> No te han abducido y no estas fuera de nuestro
                            rango
                            terraqueo
                        </li>
                        <li><i class="far fa-flushed"></i> Te has perdido apostas y no quieres que te encontremos
                        </li>
                        <li>...</li>
                    </ul>

                    <p> pasate por el buscador para localizarte</p>
                </div>

                <!-- 👆 Contenido frid --  FIN ------------------------------ -->
                <!-- ==================================================== -->
            </div>
            <!-- 👆 Perfil usuario --  FIN ------------------------------ -->
        </section>
    </div>
</div>