// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /* hostUrl: 'hl764.dinaserver.com', */
  hostUrl: 'https://lostthing.toximosi.es',
  /* hostUrl: 'https://lostthing.herokuapp.com', */
  /* hostUrl: 'mysql-5703.dinaserver.com', */
  /* hostUrl: 'localhost:3000', */
  /*  imgUrl: '../../../assets/img/', */
  imgUrl: 'https://lostthing.toximosi.es/img/',
  /* ID: 2 */





};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
