<!-- Menú principal de navegación entre las secciónes de la web/api-->

<nav class="navegacion nav-principal animated fadeInLeft">
  <div class="contenido">
    <div class="menu">
      <div class="logo">
        <span class="material-icons m-48">
          child_care
        </span>
      </div>
      <ul>
        <li class="boton">
          <a [routerLink]="['inicio']" routerLinkActive="active" alt="Ir a la sección de Inicio"
            title="Ir a la sección de Inicio">
            <span class="icono"><i class="material-icons  md-24">house</i>
            </span>
            <span class="texto">Inicio</span>
          </a>
        </li>
        <li class="boton">
          <a routerLink="/centro" alt="" title="">
            <span class="icono"><i class="material-icons">
                assistant_photo
              </i></span>
            <span class="texto">¿Qué pasa<br>donde estás?</span>
          </a>
        </li>
        <li class="boton">
          <a routerLink="/mapa" routerLinkActive="active" alt="Centro neuralgico de perdidas"
            title="Centro neuralgico de perdidas">
            <span class="icono"><span class="material-icons">
                bug_report
              </span></span>
            <!-- <span class="icono"><i class="material-icons md-24">explore</i></span> -->
            <span class="texto">Agujero de gusano</span>
          </a>
        </li>
        <li class="boton">
          <a routerLink="/objeto" routerLinkActive="active" alt=" Ir a la sección de objetos anunciados"
            title="Ir a la sección de objetos anunciados">
            <span class=" icono"><i class="material-icons md-24"><i class="fas fa-diagnoses"></i>
              </i></span>
            <span class="texto">Objetos perdidos</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="footer menu">
      <ul>
        <li class="boton">
          <a href="#" alt="" title="">
            <span class="icono icono_info"><i class="material-icons md-24">error_outline</i></span>
            <span class="texto">Información</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>